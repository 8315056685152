import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ImprintAppBar from "./ImprintAppBar";
import getLPTheme from "../getLPTheme";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EmailSubmitBar from "./EmailSubmitBar";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  const [mode, setMode] = React.useState("light");
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <ImprintAppBar mode={mode} toggleColorMode={toggleColorMode} />

      <Box
        id="imprint"
        sx={(theme) => ({
          width: "100%",
          backgroundImage:
            theme.palette.mode === "light"
              ? "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)"
              : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            useFlexGap
            sx={{ width: { xs: "100%", sm: "60%" } }}
          >
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 2,
                p: 2,
                m: 2,
                width: "100%",
              }}
            >
              <Typography
                textAlign="left"
                color="text.secondary"
                sx={{ width: { sm: "100%", md: "100%" }, px: 2 }}
              >
                {t('contactpage.heading')}
              </Typography>
              <Typography
                color="text.secondary"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === "light"
                      ? "text.secondary"
                      : "text.primary",
                  marginTop: theme.spacing(3),
                  textAlign: "left",
                  width: { sm: "100%", md: "100%" },
                  px: 2,
                })}
              >
                {t('contactpage.description')}&nbsp;
              </Typography>
              <br></br>
              <EmailSubmitBar />
              <TableContainer
                justifyContent="center"
                alignItems="center"
                sx={(theme) => ({
                  maxWidth: "50%",
                  display: 'flex',
                  margin: "auto",
                  marginTop: theme.spacing(3),
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: "visible",
                })}
              >
                <Table
                  margin="auto"
                  color="text.secondary"
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === "light"
                        ? "text.secondary"
                        : "text.primary",
                    overflow: "visible",
                  })}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t('contactpage.email')}
                      </TableCell>
                      <TableCell align="right">contact@nbetten.de</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t('contactpage.name')}
                      </TableCell>
                      <TableCell align="right">Betten Consulting</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t('contactpage.street')}
                      </TableCell>
                      <TableCell align="right">
                        Eschersheimer Landstraße 42
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t('contactpage.city')}
                      </TableCell>
                      <TableCell align="right">
                        60322, Frankfurt am Main
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
