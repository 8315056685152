import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import SitemarkIcon from "./SitemarkIcon";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Link from "@mui/material/Link";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/GitHub";
import emailjs from "@emailjs/browser";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import * as React from "react";
import { visuallyHidden } from "@mui/utils";
import { useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="https://nbetten.de/">bettenconsulting.&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  const form = useRef();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });
  const [buttonColor, setButtonColor] = React.useState("primary");

  useEffect(() => {
    if (alert.open) {
      setButtonColor(alert.severity === "success" ? "success" : "error");
      const timer = setTimeout(() => {
        setButtonColor("primary");
        setAlert({ ...alert, open: false });
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setAlert({
        open: true,
        severity: "error",
        message: t('footer.invalid_email'),
      });
      return;
    }

    setLoading(true);
    setButtonColor("grey");
    emailjs
      .sendForm("service_bww4t29", "template_1qhbgpv", form.current, {
        publicKey: "8SfhDnwcfl4kVWAbE",
      })
      .then(
        () => {
          setLoading(false);
          setAlert({
            open: true,
            severity: "success",
            message: t('footer.subscription_success'),
          });
        },
        (error) => {
          setLoading(false);
          setAlert({
            open: true,
            severity: "error",
            message: `${t('footer.subscription_failed')} ${error.text}`,
          });
        }
      )
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setAlert({
          open: true,
          severity: "error",
          message: `${t('footer.subscription_failed')} ${error.text}`,
        });
      });
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <SitemarkIcon />
            <Typography
              variant="body2"
              fontWeight={600}
              gutterBottom
              sx={{ mt: 2 }}
            >
              {t('footer.newsletter')}
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {t('footer.newsletter_description')}
            </Typography>
            <form ref={form} onSubmit={sendEmail}>
              <Stack direction="row" spacing={1} useFlexGap>
                <input
                  type="hidden"
                  id="from_name"
                  name="from_name"
                  value="Niklas Betten"
                />
                <input
                  type="hidden"
                  id="reply_to"
                  name="reply_to"
                  value="contact@nbetten.de"
                />
                <InputLabel htmlFor="email-newsletter" sx={visuallyHidden}>
                  Email
                </InputLabel>
                <TextField
                  id="email-newsletter"
                  onChange={(e) => setEmail(e.target.value)}
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  name="to_email"
                  value={email}
                  fullWidth
                  aria-label={t('footer.email_placeholder')}
                  placeholder={t('footer.email_placeholder')}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color={buttonColor}
                  disabled={loading}
                  sx={{ width: "12em" }}
                >
                  {loading ? t('footer.subscribing') : t('footer.subscribe')}
                </Button>
              </Stack>
            </form>
            <Snackbar
              open={alert.open}
              autoHideDuration={6000}
              onClose={() => {
                setButtonColor("primary");
                setAlert({ ...alert, open: false });
              }}
            >
              <Alert
                onClose={() => setAlert({ ...alert, open: false })}
                severity={alert.severity}
                sx={{ width: "100%" }}
              >
                {alert.message}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight="medium">
            {t('footer.services')}
          </Typography>
          <Link color="text.secondary" variant="body2" href="#services">
            {t('footer.services')}
          </Link>
          <Link color="text.secondary" variant="body2" href="#highlights">
            {t('footer.highlights')}
          </Link>
          <Link color="text.secondary" variant="body2" href="#pricing">
            {t('footer.pricing')}
          </Link>
          <Link color="text.secondary" variant="body2" href="#faq">
            {t('footer.faqs')}
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight="medium">
            {t('footer.company')}
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            {t('footer.about_us')}
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            {t('footer.careers')}
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            {t('footer.press')}
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight="medium">
            {t('footer.legal')}
          </Typography>
          <Link
            component={RouterLink}
            to="/imprint"
            color="text.secondary"
            variant="body2"
          >
            {t('footer.imprint')}
          </Link>
          <Link
            component={RouterLink}
            to="/gdpr"
            color="text.secondary"
            variant="body2"
          >
            {t('footer.privacy')}
          </Link>
          <Link
            component={RouterLink}
            to="/contact"
            color="text.secondary"
            variant="body2"
          >
            {t('footer.contact')}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link
            component={RouterLink}
            to="/gdpr"
            color="text.secondary"
            variant="body2"
          >
            {t('footer.privacy')}
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            {t('footer.terms_of_service')}
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/0xNB"
            target="_blank"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            target="_blank"
            href="https://www.linkedin.com/in/niklasbetten/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
