import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "@fontsource/inter";
import "@fontsource/inter/400.css";
import Imprint from "./components/Imprint";
import Contact from "./components/Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dataprotection from "./components/Dataprotection";
import Blog from "./components/Blog";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18n";

export default function RouterApp() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="blog" element={<Blog />} />
        <Route path="/blog/:postId" element={<Blog />} />
        <Route path="consulting" element={<App />} />
        <Route path="imprint" element={<Imprint />} />
        <Route path="contact" element={<Contact />} />
        <Route path="gdpr" element={<Dataprotection />} />
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <RouterApp />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
