import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ToggleColorMode from "./ToggleColorMode";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";

import LanguageSwitcher from "./LanguageSwitcher"
import Sitemark from "./SitemarkIcon";
import { useTranslation } from 'react-i18next';

function AppAppBar({ mode, toggleColorMode }) {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      scrollToSection(sectionId);
    }
  }, [location]);

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            borderRadius: "999px",
            bgcolor:
              theme.palette.mode === "light"
                ? "hsla(220, 60%, 99%, 0.6)"
                : "hsla(220, 0%, 0%, 0.7)",
            backdropFilter: "blur(24px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",
            boxShadow:
              theme.palette.mode === "light"
                ? "0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)"
                : "0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)",
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              px: 0,
            }}
          >
            <Sitemark />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link
                component={RouterLink}
                to="/blog"
                color="text.secondary"
                variant="body2"
              >
                <Button variant="text" color="info" size="small">
                  {t('blog')}
                </Button>
              </Link>
              <Link
                  component={RouterLink}
                  to="/#aboutus"
                  color="text.secondary"
                  variant="body2"
                >
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => scrollToSection("aboutus")}
              >
                {t('aboutus')}
              </Button>
              </Link>
              <Link
                  component={RouterLink}
                  to="/#services"
                  color="text.secondary"
                  variant="body2"
                >
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => scrollToSection("services")}
              >
                {t('services')}
              </Button>
              </Link>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => scrollToSection("highlights")}
              >
                {t('highlights')}
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => scrollToSection("pricing")}
              >
                {t('price')}
              </Button>
              <Button
                variant="text"
                color="info"
                size="small"
                onClick={() => scrollToSection("faq")}
              >
                {t('faqs')}
              </Button>
              <Link
                component={RouterLink}
                to="/contact"
                color="text.secondary"
                variant="body2"
              >
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  sx={{ minWidth: 0 }}
                >
                  {t('contact')}
                </Button>
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <LanguageSwitcher />
            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            {/* <Button color="primary" variant="text" size="small">
              Anmelden
            </Button>
            <Button color="primary" variant="contained" size="small">
              Registrieren
            </Button> */}
          </Box>
          <Box sx={{ display: { sm: "flex", md: "none" } }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "background.default",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}>
                  <LanguageSwitcher/>
                  <ToggleColorMode
                    mode={mode}
                    toggleColorMode={toggleColorMode}
                  />
                  </Box>

                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <Link
                  component={RouterLink}
                  to="/blog"
                  color="text.secondary"
                  variant="body2"
                >
                  <MenuItem>Blog</MenuItem>
                </Link>
                <Link
                  component={RouterLink}
                  to="/#aboutus"
                  color="text.secondary"
                  variant="body2"
                >
                <MenuItem onClick={() => scrollToSection("aboutus")}>
                  Über Uns
                </MenuItem>
                </Link>
                <MenuItem onClick={() => scrollToSection("services")}>
                  Expertise
                </MenuItem>
                <MenuItem onClick={() => scrollToSection("highlights")}>
                  Projekte
                </MenuItem>
                <MenuItem onClick={() => scrollToSection("pricing")}>
                  Preis
                </MenuItem>
                <MenuItem onClick={() => scrollToSection("faq")}>
                  FAQ's
                </MenuItem>
                <Link
                  component={RouterLink}
                  to="/contact"
                  color="text.secondary"
                  variant="body2"
                >
                  <MenuItem>Kontakt</MenuItem>
                </Link>
                {/* <MenuItem>
                  <Button color="primary" variant="contained" fullWidth>
                    Registrieren
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button color="primary" variant="outlined" fullWidth>
                    Anmelden
                  </Button>
                </MenuItem> */}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
