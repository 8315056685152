import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import EmailSubmitBar from "./EmailSubmitBar";
import { useTranslation } from 'react-i18next';

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: 400,
  marginTop: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow:
    theme.palette.mode === "light"
      ? "0 0 12px 8px hsla(220, 25%, 80%, 0.2)"
      : "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
  outline: "1px solid",
  backgroundImage: `url(${
    theme.palette.mode === "light"
      ? "/static/images/templates/templates-images/hero-light.png"
      : "/static/images/templates/templates-images/hero-dark.png"
  })`,
  backgroundSize: "cover",
  outlineColor:
    theme.palette.mode === "light"
      ? "hsla(220, 25%, 80%, 0.5)"
      : "hsla(210, 100%, 80%, 0.1)",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(3),
    height: 700,
  },
}));

export default function Hero() {

  const { t } = useTranslation();

  return (
    <Box
      id="aboutus"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)"
            : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: "100%", sm: "70%" } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row"},
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
            }}
          >
            {t('hero.free')}&nbsp;{t('hero.it')}&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "inherit",
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              {t('hero.consultation')}
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ width: { sm: "100%", md: "80%" } }}
          >
            {t('hero.thirtyminutes')}
          </Typography>
          <EmailSubmitBar />
          <Typography variant="caption" textAlign="center"></Typography>
          <Typography
            color="text.secondary"
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? "text.secondary"
                  : "text.primary",
              marginTop: theme.spacing(3),
              textAlign: "center",
              width: { sm: "100%", md: "100%" },
            })}
          >
            {t('hero.personalappointment1')}&nbsp;
            <Link href="#" color="primary">
            {t('hero.personalappointment2')}&nbsp;
            </Link>
            {t('hero.personalappointment3')}
          </Typography>
        </Stack>

        <StyledBox id="image" />
      </Container>
    </Box>
  );
}
