import * as React from "react";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { visuallyHidden } from "@mui/utils";
import { useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

export default function EmailSubmitBar() {
  const form = useRef();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({ open: false, severity: "success", message: "" });
  const [buttonColor, setButtonColor] = React.useState("primary");
  const { t } = useTranslation();

  useEffect(() => {
    if (alert.open) {
      setButtonColor(alert.severity === "success" ? "success" : "error");
      const timer = setTimeout(() => {
        setButtonColor("primary");
        setAlert({ ...alert, open: false });
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setAlert({ open: true, severity: "error", message: "Ungültige E-Mail Adresse!" });
      return;
    }

    setLoading(true);
    setButtonColor("grey")
    emailjs
      .sendForm("service_bww4t29", "template_9ez09ur", form.current, {
        publicKey: "8SfhDnwcfl4kVWAbE",
      })
      .then(
        () => {
          setLoading(false);
          setAlert({ open: true, severity: "success", message: "E-Mail gesendet!" });
        },
        (error) => {
          setLoading(false);
          setAlert({ open: true, severity: "error", message: `E-Mail konnte nicht gesendet werden: ${error.text}` });
        }
      ).catch((error) => {
        setLoading(false);
        console.error(error);
        setAlert({ open: true, severity: "error", message: `E-Mail konnte nicht gesendet werden: ${error.text}` });
      });

      const templateParams = {
        from_name: "Niklas Betten",
        reply_to: email,
        to_email: "contact@nbetten.de", // assuming 'email' is the email address you want to send to
        // add more parameters as needed by your template
      };

      emailjs.send("service_bww4t29", "template_9ez09ur", templateParams, {
        publicKey: "8SfhDnwcfl4kVWAbE",
      }).then(
        (response) => {
          console.debug("debug mail sent!", response.status, response.text);
        },
        (error) => {
          console.debug("debug mail failed...", error);
        }
      );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          useFlexGap
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 2, width: { xs: "100%", sm: "100%" } }}
        >
          <input type="hidden" id="from_name" name="from_name" value="Niklas Betten" />
          <input type="hidden" id="reply_to" name="reply_to" value="contact@nbetten.de" />
          <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
            Email
          </InputLabel>
          <TextField
            id="email-hero"
            onChange={(e) => setEmail(e.target.value)}
            required
            hiddenLabel
            size="small"
            name="to_email"
            variant="outlined"
            aria-label="Gib deine E-Mail Adresse ein"
            placeholder={t('emailfield')}
            value={email}
            sx={{ width: { xs: "100%", sm: "20em" } }}
          />
          <Button type="submit" variant="contained" color={buttonColor} disabled={loading} sx={{width: '12em'}}>
            {loading ? t('sending') : t('asknow')}
          </Button>
        </Stack>
      </form>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => {setButtonColor("primary"); setAlert({ ...alert, open: false });}}
      >
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}
