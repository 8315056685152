import * as React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import ReactCountryFlag from "react-country-flag"

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    handleClose();
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        color="primary"
        aria-label="Language switcher button"
        size="small"
      >
        <LanguageIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLanguage('de')}>
          <ReactCountryFlag                 className="emojiFlag" countryCode="DE" style={{ width: 20, marginRight: 8 }} />
          DE
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('en')}>
          <ReactCountryFlag                 className="emojiFlag" countryCode="GB" style={{ width: 20, marginRight: 8 }} />
          EN
        </MenuItem>
      </Menu>
    </div>
  );
}

export default LanguageSwitcher;
