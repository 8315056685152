import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ReactMarkdown from "react-markdown";
import Paper from "@mui/material/Paper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPTheme from "../getLPTheme";
import AppAppBar from "./AppAppBar";
import { Card, Chip, Stack, Button, Drawer } from "@mui/material";
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from 'react-i18next';

const BackgroundContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundImage:
    theme.palette.mode === "light"
      ? "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)"
      : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
  backgroundRepeat: "no-repeat",
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

const BlogPost = ({ defaultPost }) => {
  const { i18n } = useTranslation();
  const { postId } = useParams();
  const [currentPost, setCurrentPost] = useState("");

  const fetchPostContent = async (filename, lang) => {
    try {
      const response = await fetch(`/static/md/${filename}${lang === "en" ? ".en" : ""}.md`);
      if (response.ok) {
        const content = await response.text();
        setCurrentPost(content);
      } else {
        // If specific language file is not found, fall back to default language
        const fallbackResponse = await fetch(`/static/md/${filename}.md`);
        const fallbackContent = await fallbackResponse.text();
        setCurrentPost(fallbackContent);
      }
    } catch (error) {
      console.error("Error fetching post content:", error);
    }
  };

  useEffect(() => {
    const postToFetch = postId || defaultPost;
    if (postToFetch) {
      fetchPostContent(postToFetch, i18n.language);
    }
  }, [postId, defaultPost, i18n.language]);

  return (
    <Card elevation={3} sx={{ p: 4, borderRadius: 2 }}>
      <Typography>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');

              return !inline && match ? (
                <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {currentPost}
        </ReactMarkdown>
      </Typography>
    </Card>
  );
};

const BlogList = ({ posts }) => {
  return (
    <List component={Card} elevation={3}>
      {posts.map((post, index) => {
        const [title, filename, languages, author, date, tags] = post.split(":");
        const keywords = tags.split(",");
        const availableLanguages = languages.split(",");

        return (
          <ListItem
            button
            key={index}
            component={Link}
            to={`/blog/${filename}`}
          >
            <ListItemText
              primary={
                <Box display="flex" alignItems="center" justifyContent="center">
                  {title}
                  <Box ml={1} display="flex" alignItems="center">
                    {availableLanguages.includes("de") && (
                      <ReactCountryFlag countryCode="DE" style={{ width: 20, marginRight: 4 }} />
                    )}
                    {availableLanguages.includes("en") && (
                      <ReactCountryFlag countryCode="GB" style={{ width: 20, marginRight: 4 }} />
                    )}
                  </Box>
                </Box>
              }
              secondary={
                <>
                  <Typography variant="body2" color="textSecondary">
                    {author} - {date}
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ mt: 1, flexWrap: 'wrap' }}>
                    {keywords.map((keyword, index) => (
                      <Chip key={index} label={keyword} variant="outlined" size="small" />
                    ))}
                  </Stack>
                </>
              }
              sx={{ textAlign: "center" }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default function Blog() {
  const [mode, setMode] = React.useState("light");
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const [posts, setPosts] = useState([]);
  const [defaultPost, setDefaultPost] = useState("");
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const fetchPosts = async () => {
    try {
      const response = await fetch("/static/md/listing");
      const text = await response.text();
      const listings = text.split("\n").filter(Boolean);
      setPosts(listings);
      if (listings.length > 0) {
        const firstPostFilename = listings[0].split(":")[1];
        setDefaultPost(firstPostFilename);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <BackgroundContainer>
        <Container sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, py: 4 }}>
          <Box sx={{ display: {xs: "none", md: "flex"}, width: { xs: "100%", md: "20%" }, pr: { md: 2 } }}>
            <BlogList posts={posts} />
          </Box>
          <Box sx={{ width: { xs: "100%", md: "70%" }, pl: { md: 2 } }}>
            <BlogPost defaultPost={defaultPost} />
          </Box>
        </Container>
        <Button
          variant="contained"
          onClick={toggleDrawer}
          sx={{ display: { md: "none" }, position: "fixed", bottom: 16, left: "50%", transform: "translateX(-50%)" }}
        >
          Überblick
        </Button>
        <Drawer
          anchor="bottom"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          sx={{ display: { md: "none" } }}
        >
          <BlogList posts={posts} />
        </Drawer>
      </BackgroundContainer>
    </ThemeProvider>
  );
}
