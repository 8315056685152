import * as React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ImprintAppBar from "./ImprintAppBar";
import getLPTheme from "../getLPTheme";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

export default function LandingPage() {
  const [mode, setMode] = React.useState("light");
  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  //   const contactContent = [
  //     {type: 'text', value: "Niklas Betten"},
  //     {type: 'text', value: "Eschersheimer Landstraße 42"},
  //     {type: 'text', value: "60322 Frankfurt am Main"},
  //     {type: 'text', value: "Email: contact@nbetten.de"},
  //   ];

  const legalContent = [
    { type: "text", value: "Betten Consulting" },
    { type: "text", value: "Eschersheimer Landstraße 42" },
    { type: "text", value: "60322 Frankfurt am Main" },
    { type: "text", value: "Germany" },
    { type: "text", value: "VAT ID: 012 806 06731" },
    {
      type: "text",
      value:
        "Disclaimer: The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents' accuracy, completeness, or topicality.",
    },
  ];

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <ImprintAppBar mode={mode} toggleColorMode={toggleColorMode} />

      <Box
        id="imprint"
        sx={(theme) => ({
          width: "100%",
          backgroundImage:
            theme.palette.mode === "light"
              ? "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)"
              : "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
          backgroundRepeat: "no-repeat",
        })}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack
            spacing={2}
            alignItems="center"
            useFlexGap
            sx={{ width: { xs: "100%", sm: "70%" } }}
          >
            <Container id="services" sx={{ py: { xs: 4, sm: 8 } }}>
              <Box sx={{ p: 2, bgcolor: "transparent" }}>
                <Divider />
                <Box sx={{ mt: 2, bgcolor: "transparent" }}>
                  {legalContent.map((item, index) => (
                    <Typography key={index} variant="body2">
                      {item.value}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Divider />
            </Container>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
