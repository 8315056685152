import * as React from 'react';
import Box from '@mui/material/Box';

export default function SitemarkIcon() {
  return (
    <>
    <Box
      component="img"
      sx={{
        height: '2vh',
        paddingRight: '10px',
      }}
      alt="Betten Consulting Monospace Logo"
      src="/static/images/bettenconsulting_monospace_logo_transparent_blue.png"
    />
    </>
  );
}
