import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translations
const resources = {
  en: {
    translation: {
      blog: "Blog",
      aboutus: "About Us",
      services: "Services",
      highlights: "Highlights",
      price: "Price",
      faqs: "FAQ's",
      contact: "Contact",
      "hero.free": "Free",
      "hero.it": "IT",
      "hero.consultation": "Consultation",
      "hero.freeconsultation": "Free IT Initial Consultation",
      "hero.thirtyminutes":
        "Are you interested in a free 30-minute personal initial consultation for your IT project? Then sign up now!",
      "hero.personalappointment1":
        "We will create a personal appointment for you in which you can talk to us about your",
      "hero.personalappointment2": "IT Questions ans Wishes",
      "hero.personalappointment3":
        "We would be happy to develop a professional application for you that could look like this:",
      emailfield: "Your email address",
      sending: "Sending...",
      asknow: "Ask now!",
      "logo.workedtogether":
        "We have already worked together with these companies:",
      services_offered: "Offered Services",
      overview_description:
        "Below you will find a brief overview of the services we offer to our customers. We take a holistic approach to your ideas, ensuring that your project becomes a success!",
      learn_more: "Learn more",
      software_development: "Software Development",
      software_description:
        "We offer software development for web, mobile, and desktop. We tailor our solutions to your needs, developing a custom solution for you. We also offer crypto platforms and development.",
      sap_consulting: "SAP Consulting",
      sap_description:
        "We offer SAP consulting for businesses. Whether you want to implement a new system or optimize an existing one, we can help.",
      system_migration: "System Migration",
      migration_description:
        "Whether you want to migrate from an old system to a new one or switch from another provider to us, we can assist you.",
      highlights_description:
        "Discover the benefits our services offer and learn how they can help you achieve your goals.",
      adaptable_flexible: "Adaptable and Flexible",
      adaptable_description:
        "We adapt to your requirements and offer you the flexibility you need.",
      durability: "Durability",
      durability_description:
        "Rely on reliable service and a long-term partnership that will move you forward.",
      customer_satisfaction: "Customer Satisfaction",
      customer_satisfaction_description:
        "Our customers are satisfied and gladly recommend us. See for yourself.",
      innovative_development: "Innovative Development",
      innovative_development_description:
        "Whether web application, app, or backend system, we develop the right solution for you.",
      customer_service: "Customer Service",
      customer_service_description:
        "Our customer service is always there for you and offers you support that goes beyond the purchase.",
      precision_detail: "Precision in Detail",
      precision_detail_description:
        "We pay attention to every detail and ensure that you receive a product that meets your standards.",
      pricing: "Pricing",
      pricing_description: "Choose the right offer for you and start today.",
      free: "Free",
      professional: "Professional",
      enterprise: "Enterprise",
      free_price: "€0",
      on_request: "On Request",
      free_description: [
        "Consultation and Concept",
        "8h Free Work for Your Project",
        "Prototype Development",
        "Proposal Creation",
      ],
      professional_description: [
        "Professional Software Development",
        "Custom Adaptations",
        "Requirements Engineering",
        "Testing and Documentation",
        "Best Offer",
      ],
      enterprise_description: [
        "All Professional Features",
        "Prioritized Support",
        "24/7 Support",
        "SLA Guarantee for 99.9999% Uptime",
      ],
      request_free: "Request Free",
      start_now: "Start Now",
      request_quote: "Request Quote",
      recommended: "Recommended",
      faq: {
        title: "Frequently Asked Questions",
        questions: [
          {
            question: "How can I reach customer support?",
            answer:
              "You can reach our customer support team by sending an email to <Link> contact@nbetten.de </Link> or by calling our toll-free number. We are here to help you quickly.",
          },
          {
            question: "What if I am not satisfied with the service?",
            answer:
              "We have a 100% satisfaction guarantee. If you are not satisfied with our service, please contact us so we can resolve the issue. Your satisfaction is our top priority.",
          },
          {
            question: "Why should I choose the service?",
            answer:
              "By choosing our service, you get access to a team of experts who will help you solve your problems. We offer quick and efficient solutions to ensure your satisfaction. Years of experience and satisfied customers speak for themselves. Contact us today to learn more!",
          },
          {
            question: "Is there a maintenance guarantee for the service?",
            answer:
              "Yes, we offer maintenance packages for our service. If you have any issues with our service, please contact us so we can resolve them. We are here to help and ensure you are satisfied with our service.",
          },
        ],
      },
      footer: {
        newsletter: "Join the Newsletter",
        newsletter_description: "Sign up for weekly software news. Never spam!",
        subscribe: "Subscribe!",
        subscribing: "Processing...",
        services: "Services",
        highlights: "Highlights",
        pricing: "Pricing",
        faqs: "FAQs",
        company: "Company",
        about_us: "About Us",
        careers: "Careers",
        press: "Press",
        legal: "Legal",
        imprint: "Imprint",
        privacy: "Privacy Policy",
        contact: "Contact",
        terms_of_service: "Terms of Service",
        email_placeholder: "Your email address",
        invalid_email: "Invalid email address!",
        subscription_success: "Subscribed to newsletter!",
        subscription_failed: "Subscription failed: ",
      },
      appbar: {
        home: "Home",
        blog: "Blog",
        contact: "Contact",
      },
      contactpage: {
        heading: "Interested in a free 30-minute personal initial consultation for your IT project? Sign up now!",
        description: "We will create a personal appointment for you where you can talk to us about your IT questions and wishes.",
        email: "Email",
        name: "Name",
        street: "Street",
        city: "City",
      },
    },
  },
  de: {
    translation: {
      blog: "Blog",
      aboutus: "Über Uns",
      services: "Services",
      highlights: "Highlights",
      price: "Preis",
      faqs: "FAQ's",
      contact: "Kontakt",
      "hero.free": "Kostenlose",
      "hero.it": "IT",
      "hero.consultation": "Erstberatung",
      "hero.freeconsultation": "Kostenlose IT Erstberatung",
      "hero.thirtyminutes":
        "Du hast Interesse an einer kostenlosen 30 Minütigen persönlichen Erstberatung für dein IT Projekt? Dann melde dich jetzt!",
      "hero.personalappointment1":
        "Wir werden einen persönlichen Termin für dich erstellen in dem du mit uns über deine",
      "hero.personalappointment2": "IT Fragen und Wünsche",
      "hero.personalappointment3":
        "sprechen kannst. Gerne entwickeln wir eine professionelle Anwendung für dich die so aussehen kann:",
      emailfield: "Deine E-Mail Adresse",
      sending: "Wird gesendet...",
      asknow: "Jetzt anfragen!",
      "logo.workedtogether":
        "Mit diesen Firmen haben wir bereits zusammen gearbeitet:",
      services_offered: "Angebotene Services",
      overview_description:
        "Unten findest du einen kurzen Überblick über die Services die wir unseren Kunden anbieten. Dabei gehen wir ganzheitlich auf deine Vorstellungen ein und stellen somit sicher, dass dein Projekt zu einem Erfolg wird!",
      learn_more: "Erfahre mehr",
      software_development: "Software Entwicklung",
      software_description:
        "Wir bieten Softare Entwicklung für Web, Mobile und Desktop an. Dabei gehen wir auf deine Wünsche ein und entwickeln eine individuelle Lösung für dich. Außerdem bieten wir auch Crypto-Plattformen und Entwicklung an.",
      sap_consulting: "SAP Beratung",
      sap_description:
        "Wir bieten SAP Beratung für Unternehmen an. Egal ob du ein neues System einführen möchtest oder ein bestehendes System optimieren möchtest, wir helfen dir dabei.",
      system_migration: "Migration von Systemen",
      migration_description:
        "Egal ob du von einem alten System auf ein neues System migrieren möchtest oder von einem anderen Anbieter zu uns wechseln möchtest, wir helfen dir dabei.",
      highlights_description:
        "Entdecken Sie die Vorteile, die unsere Services zu bieten haben und erfahren Sie, wie es Ihnen helfen kann, Ihre Ziele zu erreichen.",
      adaptable_flexible: "Adaptierbar und flexibel",
      adaptable_description:
        "Wir passen uns Ihren Anforderungen an und bieten Ihnen die Flexibilität, die Sie benötigen.",
      durability: "Langlebigkeit",
      durability_description:
        "Verlassen Sie sich auf verlässlichen Service und eine langfristige Partnerschaft, die Sie weiterbringt.",
      customer_satisfaction: "Kundenzufriedenheit",
      customer_satisfaction_description:
        "Unsere Kunden sind zufrieden und empfehlen uns gerne weiter. Überzeugen Sie sich selbst.",
      innovative_development: "Innovative Entwicklung",
      innovative_development_description:
        "Egal ob Webanwendung, App oder Backend-System, wir entwickeln für Sie die passende Lösung.",
      customer_service: "Kundenservice",
      customer_service_description:
        "Unser Kundenservice ist stets für Sie da und bietet Ihnen Unterstützung, die über den Kauf hinausgeht.",
      precision_detail: "Präzision im Detail",
      precision_detail_description:
        "Wir achten auf jedes Detail und sorgen dafür, dass Sie ein Produkt erhalten, das Ihren Ansprüchen gerecht wird.",
      pricing: "Preis",
      pricing_description:
        "Wähle das passende Angebot für dich aus und starte noch heute.",
      free: "Kostenlos",
      professional: "Professional",
      enterprise: "Enterprise",
      free_price: "€0",
      on_request: "Auf Anfrage",
      free_description: [
        "Beratung und Konzeption",
        "8h Kostenlose Arbeit für dein Projekt",
        "Entwicklung eines Prototyps",
        "Erstellung eines Angebots",
      ],
      professional_description: [
        "Professionelle Softwareentwicklung",
        "Individuelle Anpassungen",
        "Requirements Engineering",
        "Testen und Dokumentation",
        "Bestes Angebot",
      ],
      enterprise_description: [
        "Alle Professional Funktionen",
        "Priorisierte Unterstützung",
        "24/7 Support",
        "SLA Garantie für 99.9999% Uptime",
      ],
      request_free: "Kostenlos anfragen",
      start_now: "Jetzt starten",
      request_quote: "Angebot einholen",
      recommended: "Empfohlen",
      faq: {
        title: "Häufig gestellte Fragen",
        questions: [
          {
            question: "Wie kann ich den Kundensupport erreichen?",
            answer:
              "Du kannst unser Kundensupport-Team erreichen, indem du eine E-Mail an <Link> contact@nbetten.de </Link> sendest oder unsere gebührenfreie Nummer anrufst. Wir sind hier, um Ihnen schnell zu helfen.",
          },
          {
            question:
              "Was passiert, wenn ich mit dem Service nicht zufrieden bin?",
            answer:
              "Wir haben eine 100% Zufriedenheitsgarantie. Wenn du mit unserem Service nicht zufrieden bist, kontaktiere uns bitte, damit wir das Problem beheben können. Deine Zufriedenheit ist unsere oberste Priorität.",
          },
          {
            question: "Warum sollte ich den Service wählen?",
            answer:
              "Durch die Wahl unseres Services erhältst du Zugang zu einem Team von Experten, die dir bei der Lösung deiner Probleme helfen. Wir bieten schnelle und effiziente Lösungen, um sicherzustellen, dass du zufrieden bist. Mehrjährige Erfahrung und zufriedene Kunden sprechen für sich. Kontaktiere uns noch heute, um mehr zu erfahren!",
          },
          {
            question: "Gibt es eine Wartungsgarantie für den Service?",
            answer:
              "Ja, wir bieten Wartungspakete für unseren Service an. Wenn du Probleme mit unserem Service hast, kontaktiere uns bitte, damit wir das Problem beheben können. Wir sind hier, um dir zu helfen und sicherzustellen, dass du mit unserem Service zufrieden bist.",
          },
        ],
      },
      footer: {
        newsletter: "Tritt dem Newsletter bei",
        newsletter_description:
          "Registriere dich für wöchentliche Software News. Niemals Spam!",
        subscribe: "Abonnieren!",
        subscribing: "Wird bearbeitet...",
        services: "Services",
        highlights: "Highlights",
        pricing: "Preis",
        faqs: "FAQs",
        company: "Firma",
        about_us: "Über uns",
        careers: "Karriere",
        press: "Presse",
        legal: "Rechtliches",
        imprint: "Impressum",
        privacy: "Datenschutz",
        contact: "Kontakt",
        terms_of_service: "Nutzungsbedingungen",
        email_placeholder: "Deine E-Mail Adresse",
        invalid_email: "Ungültige E-Mail Adresse!",
        subscription_success: "Newsletter abonniert!",
        subscription_failed: "Newsletter konnte nicht abonniert werden: ",
      },
      appbar: {
        home: "Home",
        blog: "Blog",
        contact: "Kontakt",
      },
      contactpage: {
        heading: "Du hast Interesse an einer kostenlosen 30 Minütigen persönlichen Erstberatung für dein IT Projekt? Dann melde dich jetzt!",
        description: "Wir werden einen persönlichen Termin für dich erstellen in dem du mit uns über deine IT Fragen und Wünsche sprechen kannst.",
        email: "Email",
        name: "Name",
        street: "Straße",
        city: "Ort",
      },
    },
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: "de", // Default language
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
